import { useTranslation } from 'next-i18next';

import { Language } from '~/constants/languages';

export const useReviews = () => {
  const { t } = useTranslation('home');
  return [
    {
      id: 1,
      text: t('anamariaReview'),
      author: 'Anamaria R.',
      language: Language.Portuguese,
    },
    {
      id: 2,
      text: t('connorReview'),
      author: 'Connor S.',
      language: Language.Spanish,
    },
    {
      id: 3,
      text: t('neelReview'),
      author: 'Neel P.',
      language: Language.French,
    },
    {
      id: 4,
      text: t('amandaReview'),
      author: 'Amanda H.',
      language: Language.Spanish,
    },
    {
      id: 5,
      text: t('voicuReview'),
      author: 'Voicu P.',
      language: Language.German,
    },
  ];
};
