import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import { ToucanColors } from '@jointoucan/toucan-design';
import { NoSsr } from '@mui/base';
import { useTheme, useMediaQuery, Skeleton } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { Element } from 'react-scroll';
import { Waypoint } from 'react-waypoint';

import { useUILanguage } from '~/components/LocaleProvider';
import { Box } from '~/components/shared/Box';
import { EmailForm } from '~/components/shared/EmailForm';
import Link from '~/components/shared/Link';
import { LottiePlayer } from '~/components/shared/LottiePlayer';
import { ResponsiveElements } from '~/components/shared/ResponsiveElements';
import { SignUpModalButton } from '~/components/shared/SignUpModalButton';
import { ToucanStats } from '~/components/shared/ToucanStats';
import { Events } from '~/constants/events';
import { Language, SupportedSourceLanguages } from '~/constants/languages';
import { useLanguageInfo } from '~/hooks/useLanguageInfo';

import { Headline } from './Headline';

interface BannerProps {
  language: Language;
  onLeaveButton?: () => void;
  onEnterButton?: () => void;
  numUsers?: number;
  numReviews?: number;
  onViewSection: () => void;
}

export const Banner = ({
  language,
  onLeaveButton,
  onEnterButton,
  numUsers,
  numReviews,
  onViewSection,
}: BannerProps) => {
  const theme = useTheme();
  const { uiLanguage: sourceLanguage } = useUILanguage();
  const { getDefaultLanguageInfo } = useLanguageInfo();
  const [lottieFilepath, setLottieFilepath] = useState<string>(
    `/animations/home/${sourceLanguage}/window-${
      getDefaultLanguageInfo(sourceLanguage as SupportedSourceLanguages).key
    }.json`,
  );
  const [hasLottieLoaded, setHasLottieLoaded] = useState(false);
  const { t } = useTranslation('home');
  const isViewportAboveSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isViewportBelowSm = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    switch (language) {
      case Language.Japanese:
        setLottieFilepath(`/animations/home/en/window-japanese-kana.json`);
        break;
      default:
        setLottieFilepath(`/animations/home/${sourceLanguage}/window-${language}.json`);
    }
  }, [language, sourceLanguage]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      css={css`
        padding: ${theme.spacing(8, 4, 0, 4)};
        ${theme.breakpoints.up('sm')} {
          position: relative;
          z-index: 100;
        }
        ${theme.breakpoints.up('md')} {
          padding: ${theme.spacing(16, 18, 0, 18)};
          padding-top: ${theme.spacing(16)};
        }
      `}
    >
      <Element name="homePageTop" className="element" />
      <Waypoint onEnter={onViewSection} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        css={css`
          max-width: 1200px;
          width: 100%;
        `}
      >
        <ResponsiveElements
          responsiveProps={{
            xs: {
              headerVariant: 'lg',
              subHeaderVariant: 'sm',
            },
            md: {
              headerVariant: 'xl',
              subHeaderVariant: 'md',
            },
          }}
        >
          <Headline
            targetLanguage={language}
            headerVariant="lg"
            subHeaderVariant="sm"
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          />
        </ResponsiveElements>

        <Waypoint onEnter={onEnterButton} onLeave={onLeaveButton} />

        <>
          {isViewportBelowSm ? (
            <EmailForm />
          ) : (
            <Box
              sx={{
                mb: {
                  sm: 3,
                  md: 4,
                },
              }}
            >
              <ResponsiveElements
                responsiveProps={{
                  sm: {
                    buttonSize: 'medium',
                  },
                  md: {
                    buttonSize: 'large',
                  },
                }}
              >
                <SignUpModalButton trackingEventName={Events.SignUpCtaModalClicked} buttonLocation="Homepage Hero" />
              </ResponsiveElements>
            </Box>
          )}
          <ResponsiveElements
            responsiveProps={{
              xs: {
                isTextOnOneLine: false,
              },
              md: {
                isTextOnOneLine: true,
              },
            }}
          >
            <ToucanStats
              textSize="sm"
              numUsers={numUsers}
              numReviews={numReviews}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: {
                  xs: 3,
                  md: 2,
                },
              }}
            />
          </ResponsiveElements>
        </>

        <Box
          display="flex"
          flexDirection="column"
          mb={4}
          gap={1}
          alignItems="center"
          css={css`
            ${theme.breakpoints.up('md')} {
              flex-direction: row;
              margin-bottom: ${theme.spacing(3)};
            }
          `}
        >
          <Link href="https://chrome.google.com/webstore/detail/toucan/lokjgaehpcnlmkebpmjiofccpklbmoci">
            <img
              src={`/images/badges/${sourceLanguage}/google-badge.svg`}
              alt={t('badgeGoogleChromeStore')}
              height="54px"
              css={css`
                display: block;
              `}
            />
          </Link>
          <Link href="https://microsoftedge.microsoft.com/addons/Edge-Extensions-Editors-Pick">
            <img
              src={`/images/badges/${sourceLanguage}/microsoft-badge.svg`}
              alt={t('badgeMicrosoft')}
              height="54px"
              css={css`
                display: block;
              `}
            />
          </Link>
        </Box>
        <Box
          position="relative"
          top="7px"
          zIndex={999}
          width="100%"
          css={css`
            ${theme.breakpoints.up('sm')} {
              top: 0;
            }
          `}
        >
          <img
            src="/images/home/toucan-hero.svg"
            alt={t('toucanWaving')}
            css={css`
              ${theme.breakpoints.up('sm')} {
                position: absolute;
                right: 0;
                top: -123px;
                z-index: 100;
                width: 208px;
              }
              ${theme.breakpoints.up('md')} {
                width: 330px;
                top: -196px;
              }
            `}
          />

          <Box
            position="relative"
            width="0"
            height="0"
            boxShadow="0px 31.3846px 94.1539px rgba(22, 22, 22, 0.08)"
            overflow="hidden"
            borderRadius="8px"
            bgcolor={ToucanColors.white}
            css={css`
              ${theme.breakpoints.up('sm')} {
                width: 100%;
                height: 43.505vw;
              }
              ${theme.breakpoints.up('md')} {
                height: 552px;
              }
            `}
          >
            {!hasLottieLoaded && (
              <Skeleton
                variant="rectangular"
                css={css`
                  width: 100%;
                  height: 100%;
                  position: absolute;
                `}
              />
            )}
            <NoSsr>
              {isViewportAboveSm && (
                <LottiePlayer
                  key={lottieFilepath}
                  src={lottieFilepath}
                  loop
                  autoplay
                  onPlayerReady={() => {
                    setHasLottieLoaded(true);
                  }}
                />
              )}
            </NoSsr>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
