import { FC } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { Trans } from 'next-i18next';

import { useUILanguage } from '~/components/LocaleProvider';
import { Box } from '~/components/shared/Box';
import { InlineTranslation } from '~/components/shared/InlineTranslation';
import { Language } from '~/constants/languages';
import { useLanguageInfo } from '~/hooks/useLanguageInfo';
import { HomeLanguageKey, useToucanTranslation } from '~/hooks/useToucanTranslation';

const { Typography } = ToucanComponents;
type TypographyVariant = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

interface HeadlineProps {
  targetLanguage: Language;
  headerVariant: TypographyVariant;
  subHeaderVariant: TypographyVariant;
  className?: string;
}

export const Headline: FC<HeadlineProps> = ({ targetLanguage, headerVariant, subHeaderVariant, className }) => {
  const theme = useTheme();
  const { uiLanguage: sourceLanguage } = useUILanguage();
  const { getLanguageInfo, getLanguageInfoFromLanguageId } = useLanguageInfo();
  const { tt: ttSourceLanguage } = useToucanTranslation(getLanguageInfoFromLanguageId(sourceLanguage)?.key as Language);
  const { tt: ttTargetLanguage } = useToucanTranslation(targetLanguage);

  const languageToucanTranslationMap: Record<Language, HomeLanguageKey> = {
    [Language.English]: HomeLanguageKey.English,
    [Language.French]: HomeLanguageKey.French,
    [Language.German]: HomeLanguageKey.German,
    [Language.Italian]: HomeLanguageKey.Italian,
    [Language.Japanese]: HomeLanguageKey.Japanese,
    [Language.Korean]: HomeLanguageKey.Korean,
    [Language.Portuguese]: HomeLanguageKey.Portuguese,
    [Language.Spanish]: HomeLanguageKey.Spanish,
    [Language.Chinese]: HomeLanguageKey.Chinese,
    [Language.Arabic]: HomeLanguageKey.Arabic,
    [Language.Hebrew]: HomeLanguageKey.Hebrew,
    [Language.Hindi]: HomeLanguageKey.Hindi,
  };

  return (
    <Box className={className}>
      <Typography
        variant={headerVariant}
        font="secondary"
        color={ToucanColors.gray[600]}
        css={css`
          text-align: center;
          margin-bottom: ${theme.spacing(2)};
          ${theme.breakpoints.up('md')} {
            margin-bottom: ${theme.spacing(3)};
          }
        `}
      >
        <span data-testid="home-banner-headline">
          <Trans
            i18nKey="learnWithoutTrying"
            ns="home"
            values={{
              language: ttTargetLanguage(languageToucanTranslationMap[targetLanguage]),
            }}
            components={[
              <InlineTranslation sourcePhrase={getLanguageInfo(targetLanguage).language} language={targetLanguage}>
                aaa
              </InlineTranslation>,
            ]}
          />
        </span>
      </Typography>
      <Typography
        variant={subHeaderVariant}
        css={css`
          text-align: center;
          max-width: 850px;
          margin-bottom: ${theme.spacing(4)};
        `}
        color={ToucanColors.gray[500]}
      >
        <Trans
          i18nKey="learnWhileBrowsing"
          ns="home"
          values={{
            language: ttTargetLanguage(HomeLanguageKey.Language),
          }}
          components={[
            <InlineTranslation sourcePhrase={ttSourceLanguage(HomeLanguageKey.Language)} language={targetLanguage}>
              aaa
            </InlineTranslation>,
          ]}
        />
      </Typography>
    </Box>
  );
};
