import { useContext } from 'react';

import GlobalContext from '~/context/GlobalContext';

export const useMobileDeviceUserAgent = () => {
  const { isMobileDevice } = useContext(GlobalContext);
  return isMobileDevice;
};

export default useMobileDeviceUserAgent;
