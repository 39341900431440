import { useState } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';

import { Events } from '~/constants/events';
import { email as emailRegex } from '~/constants/regex';
import { trackEvent } from '~/helpers/analytics';

import { Box, BoxImage } from './Box';

const { Typography, Button, ToucanIcon, TextField } = ToucanComponents;

interface EmailFormInputs {
  email: string;
}

export const EmailForm = () => {
  const [hasEmailBeenSubmitted, setHasEmailBeenSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailFormInputs>();
  const theme = useTheme();
  const { t } = useTranslation();
  const getToucanTextKey = 'emailGetToucanForFree';

  const onClickSubmit = () => {
    trackEvent({
      name: Events.HomePageEmailAddedForInstallLifecycleCampaign,
    });

    setHasEmailBeenSubmitted(true);
  };

  const onClickGetDownloadLink = () => {
    setHasEmailBeenSubmitted(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      mb={2}
      borderRadius="8px"
      border={hasEmailBeenSubmitted ? `1px solid ${ToucanColors.teal[100]}` : 'none'}
      bgcolor={ToucanColors.teal[100]}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems={hasEmailBeenSubmitted ? 'center' : 'flex-start'}
        p={3}
        borderRadius="8px"
        textAlign="center"
        position="relative"
      >
        {hasEmailBeenSubmitted && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            borderRadius="50%"
            width="52px"
            height="52px"
            mb={2}
            bgcolor={ToucanColors.teal[200]}
          >
            <ToucanIcon
              icon="check"
              css={css`
                color: ${ToucanColors.teal[400]};
                width: 20px;
                height: auto;
              `}
            />
          </Box>
        )}
        {!hasEmailBeenSubmitted && (
          <BoxImage
            src="/images/home/toucan-base.svg"
            alt={t('toucanWaving')}
            css={css`
              position: absolute;
              right: 0px;
              z-index: 100;
            `}
          />
        )}
        <Typography
          variant="md"
          font="secondary"
          color={ToucanColors.gray[600]}
          css={css`
            text-align: ${hasEmailBeenSubmitted ? 'center' : 'left'};
          `}
        >
          {!hasEmailBeenSubmitted ? t(getToucanTextKey) : t('emailCheckEmail')}
        </Typography>
        <Typography
          variant="sm"
          color={ToucanColors.gray[500]}
          css={css`
            margin-top: ${theme.spacing(2)};
            text-align: ${!hasEmailBeenSubmitted ? 'left' : 'center'};
          `}
        >
          {!hasEmailBeenSubmitted ? t('emailOnlyLaptopDesktopRightNow') : t('emailCarrierPigeonOnItsWay')}
        </Typography>

        {!hasEmailBeenSubmitted && (
          <TextField
            type="email"
            label={t('sharedEmailField')}
            isFullWidth
            hasError={!!errors.email}
            helperText={errors?.email?.message}
            css={css`
              margin-top: ${theme.spacing(2)};
            `}
            {...register('email', {
              required: `${t('pleaseEnterValidEmail')}`,
              pattern: {
                value: emailRegex,
                message: t('pleaseEnterValidEmail'),
              },
            })}
          />
        )}
        {!hasEmailBeenSubmitted && (
          <Button
            type="primary"
            size="medium"
            isReversed={false}
            css={css`
              margin-top: ${theme.spacing(2)};
              width: 100%;
            `}
            onClick={handleSubmit(onClickSubmit)}
          >
            {t('emailSubmit')}
          </Button>
        )}
      </Box>
      {hasEmailBeenSubmitted && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          css={css`
            background-color: #ffffff;
            width: 100%;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          `}
        >
          <Button
            type="text"
            onClick={onClickGetDownloadLink}
            css={css`
              margin-top: ${theme.spacing(3)};
              margin-bottom: ${theme.spacing(3)};
            `}
          >
            {t('emailSendToDifferentEmail')}
          </Button>
        </Box>
      )}
    </Box>
  );
};
