import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Trans, useTranslation } from 'next-i18next';
import { scroller } from 'react-scroll';
import { Waypoint } from 'react-waypoint';

import { useUILanguage } from '~/components/LocaleProvider';
import { ResponsiveElements } from '~/components/shared/ResponsiveElements';
import { SignUpModalButton } from '~/components/shared/SignUpModalButton';
import { Events } from '~/constants/events';
import { Language } from '~/constants/languages';
import { useLanguageInfo } from '~/hooks/useLanguageInfo';
import { useMobileDeviceUserAgent } from '~/hooks/useMobileDeviceUserAgent';
import { HomeLanguageKey, useToucanTranslation } from '~/hooks/useToucanTranslation';

import { InlineTranslation } from '../shared/InlineTranslation';

import Carousel from './Carousel';

const { Typography, Button } = ToucanComponents;

interface ReviewsProps {
  language: Language;
  numUsers?: number;
  onViewSection: () => void;
  onViewCta: () => void;
}

export const Reviews = ({ language, numUsers, onViewSection, onViewCta }: ReviewsProps) => {
  const theme = useTheme();
  const isViewportBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileUserAgent = useMobileDeviceUserAgent();
  const { t } = useTranslation('home');
  const { uiLanguage: sourceLanguage } = useUILanguage();
  const { getLanguageInfoFromLanguageId } = useLanguageInfo();
  const { tt: ttSourceLanguage } = useToucanTranslation(getLanguageInfoFromLanguageId(sourceLanguage)?.key as Language);
  const { tt: ttTargetLanguage } = useToucanTranslation(language);

  const scrollToTop = () => {
    scroller.scrollTo('homePageTop', {
      duration: 1500,
      delay: 100,
      smooth: 'easeInOutQuart',
      offset: -theme.spacing(7),
    });
  };

  return (
    <div
      data-testid="reviews-section"
      css={css`
        background: url(/images/home/reviews-background.svg) repeat;
        background-size: cover;
        background-position: -210px -470px;
        padding-top: ${theme.spacing(10)};
        padding-bottom: ${theme.spacing(9)};
        ${theme.breakpoints.up('sm')} {
          padding-top: ${theme.spacing(8)};
          padding-bottom: ${theme.spacing(15)};
          background-position: top center;
          background-repeat: no-repeat;
        }
        ${theme.breakpoints.up('md')} {
          padding-top: ${theme.spacing(12)};
          padding-bottom: ${theme.spacing(20)};
        }
      `}
    >
      <Waypoint onEnter={onViewSection} />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-bottom: ${theme.spacing(15)};
          ${theme.breakpoints.up('sm')} {
            flex-direction: row;
            margin-bottom: ${theme.spacing(22)};
          }
          ${theme.breakpoints.up('md')} {
            margin-bottom: ${theme.spacing(33)};
          }
        `}
      >
        <div
          css={css`
            flex: 1;
            display: flex;
            padding-left: ${theme.spacing(4)};
            ${theme.breakpoints.up('sm')} {
              justify-content: center;
              align-items: center;
              padding-left: ${theme.spacing(8)};
              padding-right: ${theme.spacing(11)};
            }
          `}
        >
          <div
            css={css`
              margin-bottom: ${theme.spacing(6)};
              ${theme.breakpoints.up('sm')} {
                margin-bottom: 0;
              }
            `}
          >
            <ResponsiveElements
              responsiveProps={{
                xs: {
                  variant: 'lg',
                },
                md: {
                  variant: 'xl',
                },
              }}
            >
              <Typography
                font="secondary"
                color={ToucanColors.white}
                css={css`
                  margin-bottom: ${theme.spacing(2)};
                  br {
                    display: none;
                  }
                  width: 305px;
                  ${theme.breakpoints.up('sm')} {
                    br {
                      display: inline;
                    }
                    width: auto;
                  }
                `}
              >
                <Trans
                  i18nKey="trustedByReviews"
                  ns="home"
                  values={{
                    num: numUsers?.toLocaleString() ?? (700_000).toLocaleString(),
                    geniuses: ttTargetLanguage(HomeLanguageKey.Geniuses),
                  }}
                  components={[
                    <br />,
                    <br />,
                    <InlineTranslation sourcePhrase={ttSourceLanguage(HomeLanguageKey.Geniuses)} language={language}>
                      aaa
                    </InlineTranslation>,
                  ]}
                />
              </Typography>
            </ResponsiveElements>
            <ResponsiveElements
              responsiveProps={{
                xs: {
                  variant: 'sm',
                },
                md: {
                  variant: 'md',
                },
              }}
            >
              <Typography color={ToucanColors.gray[100]}>{t('dontTakeOurWordForIt')}</Typography>
            </ResponsiveElements>
          </div>
        </div>
        <div
          css={css`
            flex: 2;
            width: 100%;
            overflow: hidden;
            padding-left: ${theme.spacing(2)};
            ${theme.breakpoints.up('sm')} {
              padding-left: 0;
            }
          `}
        >
          <Carousel />
        </div>
      </div>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        css={css`
          padding: ${theme.spacing(0, 4)};
          text-align: center;
          ${theme.breakpoints.up('sm')} {
            text-align: left;
          }
        `}
      >
        <ResponsiveElements
          responsiveProps={{
            xs: {
              variant: 'lg',
            },
            md: {
              variant: 'xl',
            },
          }}
        >
          <Typography
            font="secondary"
            color={ToucanColors.white}
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            {t('learnQuicklyAndEfficiently')}
          </Typography>
        </ResponsiveElements>
        <ResponsiveElements
          responsiveProps={{
            xs: {
              variant: 'sm',
            },
            md: {
              variant: 'md',
            },
          }}
        >
          <Typography
            color={ToucanColors.gray[100]}
            css={css`
              margin-bottom: ${theme.spacing(4)};
              ${theme.breakpoints.up('sm')} {
                margin-bottom: ${theme.spacing(6)};
              }
            `}
          >
            <Trans
              i18nKey="installFreeAndLearn"
              ns="home"
              values={{ language: ttTargetLanguage(HomeLanguageKey.Language) }}
              components={[
                <InlineTranslation sourcePhrase={ttSourceLanguage(HomeLanguageKey.Language)} language={language}>
                  aaa
                </InlineTranslation>,
              ]}
            />
          </Typography>
        </ResponsiveElements>
        <Waypoint onEnter={onViewCta} />
        {isViewportBelowSm || isMobileUserAgent ? (
          <Button type="primary" size="medium" onClick={scrollToTop}>
            {t('emailGetDownloadLink')}
          </Button>
        ) : (
          <>
            <ResponsiveElements
              responsiveProps={{
                sm: {
                  buttonSize: 'medium',
                },
                md: {
                  buttonSize: 'large',
                },
              }}
            >
              <SignUpModalButton trackingEventName={Events.SignUpCtaModalClicked} buttonLocation="Homepage Reviews" />
            </ResponsiveElements>
          </>
        )}
      </Box>
    </div>
  );
};
