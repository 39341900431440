import { forwardRef } from 'react';

import { css, SerializedStyles } from '@emotion/react';
import { IPlayerProps, Player } from '@lottiefiles/react-lottie-player';
import type { AnimationItem } from 'lottie-web';

import { Box, BoxProps } from './Box';

export type LottiePlayerControls = AnimationItem;

export type BoxPlayerProps = IPlayerProps & BoxProps;

export interface LottiePlayerProps extends BoxPlayerProps {
  src: string;
  playerCss?: SerializedStyles;
  lfPlayerCss?: SerializedStyles;
  css?: SerializedStyles;
  onPlayerReady?: (playerControls: LottiePlayerControls) => void;
  containerless?: boolean;
}

export const LottiePlayer = forwardRef<Player, LottiePlayerProps>(
  (
    {
      css: passedCss,
      playerCss,
      lfPlayerCss,
      onPlayerReady,
      id,
      onEvent,
      onStateChange,
      onBackgroundChange,
      autoplay,
      background,
      children,
      controls,
      direction,
      hover,
      loop,
      renderer,
      speed,
      rendererSettings,
      keepLastFrame,
      src,
      containerless,
      ...props
    },
    ref,
  ) => {
    const player = (
      <Player
        ref={ref}
        id={id}
        onEvent={onEvent}
        onStateChange={onStateChange}
        onBackgroundChange={onBackgroundChange}
        autoplay={autoplay}
        background={background}
        controls={controls}
        direction={direction}
        hover={hover}
        loop={loop}
        renderer={renderer}
        speed={speed}
        rendererSettings={rendererSettings}
        keepLastFrame={keepLastFrame}
        src={src}
        css={playerCss}
        lottieRef={onPlayerReady}
      >
        {children}
      </Player>
    );

    return !containerless ? (
      <Box
        css={css`
          ${passedCss}
          .lf-player-container {
            ${lfPlayerCss}
          }
        `}
        {...props}
      >
        {player}
      </Box>
    ) : (
      player
    );
  },
);
