import { FC, useState, useRef, useEffect } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { Player } from '@lottiefiles/react-lottie-player';
import { NoSsr } from '@mui/base';
import { useTheme, Box, Skeleton, useMediaQuery } from '@mui/material';
import { useTranslation, Trans } from 'next-i18next';
import { Waypoint } from 'react-waypoint';

import Divider from '~/components/shared/Divider';
import { Hidden } from '~/components/shared/Hidden';
import { InlineTranslation } from '~/components/shared/InlineTranslation';
import { LottiePlayer } from '~/components/shared/LottiePlayer';
import { ResponsiveElements } from '~/components/shared/ResponsiveElements';
import { Language } from '~/constants/languages';
import { useLanguageInfo } from '~/hooks/useLanguageInfo';
import { HomeLanguageKey, useToucanTranslation } from '~/hooks/useToucanTranslation';

import { useUILanguage } from '../LocaleProvider';

const { Typography, TransparentButton } = ToucanComponents;

interface AnimationSelectorProps {
  stepNum: number;
  isActive?: boolean;
  onClick: () => void;
  hasBottomMargin?: boolean;
}

const AnimationSelector: FC<AnimationSelectorProps> = ({
  children,
  stepNum,
  isActive = false,
  onClick,
  hasBottomMargin = true,
}) => {
  const theme = useTheme();

  return (
    <TransparentButton
      type="button"
      css={css`
        margin-bottom: ${hasBottomMargin ? theme.spacing(3) : 0};
        text-align: left;
      `}
      onClick={onClick}
    >
      <Typography
        variant="sm"
        isBold
        color={ToucanColors.white}
        css={css`
          text-align: left;
          opacity: 1;
          ${theme.breakpoints.up('sm')} {
            margin-bottom: ${theme.spacing(1)};
            color: ${isActive ? ToucanColors.white : ToucanColors.teal[100]};
            opacity: ${isActive ? 1 : 0.5};
          }
        `}
      >
        <span
          css={css`
            margin-right: ${theme.spacing(1)};
          `}
        >
          {stepNum}
        </span>
        {children}
      </Typography>
      <Hidden smDown>
        <Divider
          css={css`
            background-color: ${ToucanColors.white};
            opacity: ${isActive ? 1 : 0};
          `}
        />
      </Hidden>
    </TransparentButton>
  );
};

interface HowItWorksProps {
  language: Language;
  onViewSection: () => void;
}

export const HowItWorks: FC<HowItWorksProps> = ({ language, onViewSection }) => {
  const { t } = useTranslation('home');
  const { uiLanguage: sourceLanguage } = useUILanguage();
  const { getLanguageInfoFromLanguageId } = useLanguageInfo();
  const { tt: ttSourceLanguage } = useToucanTranslation(getLanguageInfoFromLanguageId(sourceLanguage)?.key as Language);
  const { tt: ttTargetLanguage } = useToucanTranslation(language);
  const theme = useTheme();
  const [activeHowItWorksAnimationStep, setActiveHowItWorksAnimationStep] = useState<number>();
  const isViewportAboveSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [hasLottieLoaded, setHasLottieLoaded] = useState(false);
  const lottiePlayer = useRef<Player | null>(null);

  const howItWorksAnimations = [
    '/animations/home/en/how-it-works-step1.json',
    '/animations/home/en/how-it-works-step2.json',
    '/animations/home/en/how-it-works-step3.json',
  ];

  const handleSectionComingIntoView = () => {
    setActiveHowItWorksAnimationStep(0);
    onViewSection();
  };

  useEffect(() => {
    if (lottiePlayer.current) {
      lottiePlayer?.current?.play();
    }
  }, [activeHowItWorksAnimationStep, lottiePlayer.current]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      css={css`
        position: relative;
        margin-top: -36px;
        padding: ${theme.spacing(6, 4)};
        background: url(/images/home/how-it-works-background.svg) no-repeat top center;
        background-size: cover;
        z-index: 998;
        ${theme.breakpoints.up('sm')} {
          margin-top: ${theme.spacing(-7)};
          padding-top: ${theme.spacing(24)};
          padding-bottom: 0;
          z-index: 1;
        }
        ${theme.breakpoints.up('md')} {
          margin-top: ${theme.spacing(-15)};
          padding-top: ${theme.spacing(45)};
          padding: ${theme.spacing(45, 18, 0, 18)};
        }
      `}
    >
      <Waypoint onEnter={handleSectionComingIntoView} />
      <Box
        css={css`
          max-width: 1200px;
          width: 100%;
          ${theme.breakpoints.up('sm')} {
            display: flex;
            justify-content: center;
            align-items: flex-end;
          }
          ${theme.breakpoints.up('md')} {
            align-items: center;
          }
        `}
      >
        <div
          css={css`
            ${theme.breakpoints.up('sm')} {
              flex: 0.8;
              display: flex;
              justify-content: center;
              margin-bottom: ${theme.spacing(7)};
            }
          `}
        >
          <div
            css={css`
              ${theme.breakpoints.up('sm')} {
                width: 240px;
              }
              ${theme.breakpoints.up('md')} {
                width: auto;
              }
            `}
          >
            <ResponsiveElements
              responsiveProps={{
                xs: {
                  variant: 'lg',
                },
                md: {
                  variant: 'xl',
                },
              }}
            >
              <Typography
                font="secondary"
                color={ToucanColors.white}
                css={css`
                  margin-bottom: ${theme.spacing(5)};
                  ${theme.breakpoints.up('sm')} {
                    margin-bottom: ${theme.spacing(4)};
                  }
                `}
              >
                {t('howItWorks')}
              </Typography>
            </ResponsiveElements>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <AnimationSelector
                stepNum={1}
                isActive={activeHowItWorksAnimationStep === 0}
                onClick={() => setActiveHowItWorksAnimationStep(0)}
              >
                {t('installExtensionFree')}
              </AnimationSelector>
              <AnimationSelector
                stepNum={2}
                isActive={activeHowItWorksAnimationStep === 1}
                onClick={() => setActiveHowItWorksAnimationStep(1)}
              >
                <Trans
                  i18nKey="chooseYourLanguage"
                  ns="home"
                  values={{
                    language: ttTargetLanguage(HomeLanguageKey.Language),
                  }}
                  components={[
                    <InlineTranslation sourcePhrase={ttSourceLanguage(HomeLanguageKey.Language)} language={language}>
                      aaa
                    </InlineTranslation>,
                  ]}
                />
              </AnimationSelector>
              <AnimationSelector
                stepNum={3}
                isActive={activeHowItWorksAnimationStep === 2}
                onClick={() => setActiveHowItWorksAnimationStep(2)}
                hasBottomMargin={false}
              >
                {t('browseAsNormal')}
              </AnimationSelector>
            </Box>
          </div>
        </div>

        <Box flex={1}>
          <Box
            position="relative"
            width="100%"
            height="0"
            overflow="hidden"
            borderRadius="8px"
            bgcolor={ToucanColors.white}
            css={css`
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              ${theme.breakpoints.up('sm')} {
                height: 27.005vw;
              }
              ${theme.breakpoints.up('md')} {
                max-width: 682px;
              }
            `}
          >
            {!hasLottieLoaded && (
              <Skeleton
                variant="rectangular"
                css={css`
                  width: 100%;
                  height: 100%;
                  position: absolute;
                `}
              />
            )}
            <NoSsr>
              {isViewportAboveSm && (
                <>
                  {typeof activeHowItWorksAnimationStep !== 'undefined' && (
                    <LottiePlayer
                      ref={lottiePlayer}
                      key={howItWorksAnimations[activeHowItWorksAnimationStep]}
                      src={howItWorksAnimations[activeHowItWorksAnimationStep]}
                      loop={false}
                      autoplay={false}
                      onPlayerReady={() => {
                        setHasLottieLoaded(true);
                        lottiePlayer?.current?.play();
                      }}
                      onEvent={(event) => {
                        if (event === 'complete') {
                          setActiveHowItWorksAnimationStep((lastStepNum) => {
                            if (typeof lastStepNum === 'undefined') {
                              return lastStepNum;
                            }

                            if (lastStepNum === 2) {
                              return 0;
                            }

                            return lastStepNum + 1;
                          });
                        }
                      }}
                    />
                  )}
                </>
              )}
            </NoSsr>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
