import semver from 'semver';

import { SupportedSourceLanguages, LanguageId } from '~/constants/languages';
import { useExtension } from '~/hooks/useExtension';

export const useSupportedLanguages = ({ areAllLanguagesIncluded = false } = {}) => {
  const { version } = useExtension();

  let supportedLanguages: Record<SupportedSourceLanguages, LanguageId[]> = {
    [LanguageId.En]: [
      LanguageId.Fr,
      LanguageId.De,
      LanguageId.It,
      LanguageId.Ja,
      LanguageId.Ko,
      LanguageId.Pt,
      LanguageId.Es,
      LanguageId.Zh,
      LanguageId.Ar,
      LanguageId.He,
      LanguageId.Hi,
    ],
    [LanguageId.Es]: [LanguageId.En],
    [LanguageId.Pt]: [LanguageId.En],
    [LanguageId.De]: [LanguageId.En],
    [LanguageId.Fr]: [LanguageId.En],
    [LanguageId.Hi]: [LanguageId.En],
    [LanguageId.Ko]: [LanguageId.En],
    [LanguageId.It]: [LanguageId.En],
  };

  // Language grid feature launch
  if (semver.gte(version || '0.0.0', '2.10.0') || areAllLanguagesIncluded) {
    supportedLanguages = {
      [LanguageId.En]: [
        LanguageId.Fr,
        LanguageId.De,
        LanguageId.It,
        LanguageId.Ja,
        LanguageId.Ko,
        LanguageId.Pt,
        LanguageId.Es,
        LanguageId.Zh,
        LanguageId.Ar,
        LanguageId.He,
        LanguageId.Hi,
      ],
      [LanguageId.Es]: [
        LanguageId.En,
        LanguageId.Fr,
        LanguageId.De,
        LanguageId.It,
        LanguageId.Ja,
        LanguageId.Ko,
        LanguageId.Pt,
        LanguageId.Zh,
        LanguageId.Ar,
        LanguageId.He,
        LanguageId.Hi,
      ],
      [LanguageId.Pt]: [
        LanguageId.En,
        LanguageId.Fr,
        LanguageId.De,
        LanguageId.It,
        LanguageId.Ja,
        LanguageId.Ko,
        LanguageId.Es,
        LanguageId.Zh,
        LanguageId.Ar,
        LanguageId.He,
        LanguageId.Hi,
      ],
      [LanguageId.De]: [
        LanguageId.En,
        LanguageId.Fr,
        LanguageId.It,
        LanguageId.Ja,
        LanguageId.Ko,
        LanguageId.Pt,
        LanguageId.Es,
        LanguageId.Zh,
        LanguageId.Ar,
        LanguageId.He,
        LanguageId.Hi,
      ],
      [LanguageId.Fr]: [
        LanguageId.En,
        LanguageId.De,
        LanguageId.It,
        LanguageId.Ja,
        LanguageId.Ko,
        LanguageId.Pt,
        LanguageId.Es,
        LanguageId.Zh,
        LanguageId.Ar,
        LanguageId.He,
        LanguageId.Hi,
      ],
      [LanguageId.Hi]: [
        LanguageId.En,
        LanguageId.Fr,
        LanguageId.De,
        LanguageId.It,
        LanguageId.Ja,
        LanguageId.Ko,
        LanguageId.Pt,
        LanguageId.Es,
        LanguageId.Zh,
        LanguageId.Ar,
        LanguageId.He,
      ],
      [LanguageId.Ko]: [
        LanguageId.En,
        LanguageId.Fr,
        LanguageId.De,
        LanguageId.It,
        LanguageId.Ja,
        LanguageId.Pt,
        LanguageId.Es,
        LanguageId.Zh,
        LanguageId.Ar,
        LanguageId.He,
        LanguageId.Hi,
      ],
      [LanguageId.It]: [
        LanguageId.En,
        LanguageId.Fr,
        LanguageId.De,
        LanguageId.Ja,
        LanguageId.Ko,
        LanguageId.Pt,
        LanguageId.Es,
        LanguageId.Zh,
        LanguageId.Ar,
        LanguageId.He,
        LanguageId.Hi,
      ],
    };
  }

  return {
    supportedLanguages,
  };
};
