import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';

const { Button, ToucanIcon } = ToucanComponents;

const Container = ({ children, onNextClick }) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
      `}
    >
      <div
        css={css`
          position: absolute;
          height: 100%;
          z-index: 100;
          right: ${theme.spacing(2)};
          display: flex;
          align-items: center;
          ${theme.breakpoints.up('sm')} {
            right: ${theme.spacing(3)};
          }
          ${theme.breakpoints.up('md')} {
            right: ${theme.spacing(7)};
          }
        `}
      >
        <Button
          type="primary"
          size="large"
          isReversed
          onClick={onNextClick}
          css={css`
            width: 48px;
            height: 48px;
            min-width: 0;
            border-radius: 50%;
            box-shadow: 0px 12px 36px rgba(22, 22, 22, 0.12);
            border: 1px solid ${ToucanColors.blue[100]};
            padding: 0;
            ${theme.breakpoints.up('sm')} {
              width: 64px;
              height: 64px;
            }
          `}
        >
          <ToucanIcon
            icon="chevron-right"
            css={css`
              color: ${ToucanColors.blue[300]};
            `}
          />
        </Button>
      </div>
      <div
        css={css`
          display: inline-flex;
          height: 100%;
        `}
      >
        {children}
      </div>
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  onNextClick: PropTypes.func.isRequired,
};

export default Container;
