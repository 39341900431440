import { ExistingPartner } from '~/constants/partner';

export const isExistingPartner = (partner: string): partner is keyof typeof ExistingPartner => {
  return Object.keys(ExistingPartner).includes(partner);
};

export const formatPartnerBanner = (partner: string) => {
  return isExistingPartner(partner)
    ? ExistingPartner[partner]
    : partner
        .split('-')
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join(' ');
};
