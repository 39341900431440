import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { ResponsiveElements } from '~/components/shared/ResponsiveElements';

import Author from './Author';

const { Typography, Card } = ToucanComponents;

const MotionCard = motion(Card);

const CarouselCard = ({
  id,
  visibleOpacity,
  children,
  author,
  language,
  isCardVisible,
  animationTimeSeconds,
  animationDelaySeconds = 0,
  className,
}) => {
  const theme = useTheme();
  const { t } = useTranslation('home');

  return (
    <MotionCard
      className={className}
      key={id}
      initial={{ opacity: 0 }}
      animate={{
        opacity: isCardVisible ? visibleOpacity : 0,
      }}
      transition={{
        ease: 'easeInOut',
        duration: animationTimeSeconds,
        delay: animationDelaySeconds,
      }}
      css={css`
        padding: ${theme.spacing(11, 4)};
        position: relative;
        min-height: 378px;
        width: 311px;
        max-width: 70%;
        ${theme.breakpoints.up('md')} {
          padding: ${theme.spacing(15, 16, 20, 8)};
          width: 512px;
          min-height: 522px;
        }
        ${theme.breakpoints.up('lg')} {
          padding-right: ${theme.spacing(18)};
          width: 688px;
        }
      `}
    >
      <span data-testid="carousel-card-content">
        <img
          src="/images/quote.svg"
          alt={t('quotationMark')}
          css={css`
            position: absolute;
            color: ${ToucanColors.teal[300]};
            top: ${theme.spacing(4)};
            left: ${theme.spacing(4)};
            opacity: ${visibleOpacity || 1};
            ${theme.breakpoints.up('md')} {
              top: ${theme.spacing(8)};
              left: ${theme.spacing(8)};
            }
          `}
        />
        <ResponsiveElements
          responsiveProps={{
            xs: {
              variant: 'sm',
            },
            md: {
              variant: 'md',
            },
          }}
        >
          <Typography isBold>{children}</Typography>
        </ResponsiveElements>
        <Author name={author} language={language} />
      </span>
    </MotionCard>
  );
};

CarouselCard.propTypes = {
  id: PropTypes.number.isRequired,
  visibleOpacity: PropTypes.number,
  children: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  isCardVisible: PropTypes.bool.isRequired,
  animationTimeSeconds: PropTypes.number,
  animationDelaySeconds: PropTypes.number,
  className: PropTypes.string,
};

CarouselCard.defaultProps = {
  visibleOpacity: 1,
  animationTimeSeconds: 0.7,
  animationDelaySeconds: 0,
  className: null,
};

export default CarouselCard;
