import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors, TypographyVariant } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';

import { LanguageId } from '~/constants/languages';

import { useUILanguage } from '../LocaleProvider';

import { Box } from './Box';

const { Typography, ToucanIcon } = ToucanComponents;

interface ToucanStatsProps {
  numReviews: number;
  numUsers: number;
  className?: string;
  textSize: TypographyVariant;
  sx?: SxProps<Theme>;
  isTextOnOneLine?: boolean;
}

export const ToucanStats = ({
  numReviews,
  numUsers,
  sx,
  textSize = 'xs',
  className,
  isTextOnOneLine,
}: Partial<ToucanStatsProps>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { uiLanguage: sourceLanguage } = useUILanguage();

  const isOnOneLine =
    isTextOnOneLine !== undefined
      ? isTextOnOneLine
      : sourceLanguage !== LanguageId.Pt && sourceLanguage !== LanguageId.De;
  const isCentered = sourceLanguage === LanguageId.Pt || sourceLanguage === LanguageId.De;

  if (!numReviews || !numUsers) {
    return null;
  }

  return (
    <Box sx={sx} className={className}>
      <Box display="flex" flexDirection={isOnOneLine ? 'row' : 'column'}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={isCentered ? 'center' : ''}
          css={css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          <Box display="flex">
            {[1, 2, 3, 4, 5].map((key) => (
              <ToucanIcon
                key={`star-${key}`}
                icon="star"
                css={css`
                  color: ${ToucanColors.yellow[300]};
                  margin-right: ${theme.spacing(1)};
                `}
              />
            ))}
          </Box>
          <Typography variant={textSize} color={ToucanColors.gray[400]}>
            {t('numReviews', { num: numReviews.toLocaleString() })}
          </Typography>
        </Box>
        {isOnOneLine && (
          <Typography
            variant={textSize}
            color={ToucanColors.gray[400]}
            css={css`
              margin: 0 ${theme.spacing(1)};
            `}
          >
            ·
          </Typography>
        )}
        <Typography
          variant={textSize}
          color={ToucanColors.gray[400]}
          css={
            isCentered &&
            css`
              display: flex;
              justify-content: center;
            `
          }
        >
          {t('trustedByGeniuses', { num: numUsers.toLocaleString() })}
        </Typography>
      </Box>
    </Box>
  );
};
