import { FC } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';

import { useUILanguage } from '~/components/LocaleProvider';
import { Language } from '~/constants/languages';
import { useLanguageInfo } from '~/hooks/useLanguageInfo';
import { useSupportedLanguages } from '~/hooks/useSupportedLanguages';

const { Typography, TransparentButton } = ToucanComponents;

interface LanguageSelectorProps {
  language: Language;
  onClick: (language: Language) => void;
  isActive?: boolean;
}

const LanguageSelector: FC<LanguageSelectorProps> = ({ language, onClick, isActive = false }) => {
  const theme = useTheme();
  const { getLanguageInfo } = useLanguageInfo();
  const languageInfo = getLanguageInfo(language);

  return (
    <TransparentButton
      data-testid={`language-selection-${languageInfo.language}`}
      onClick={() => onClick(language)}
      css={css`
        position: relative;
        background-color: ${ToucanColors.white};
        padding: 20px 24px;
        height: 100%;
        display: inline-flex;
        align-items: center;
        &:hover {
          & > div {
            height: 4px;
            background-color: ${isActive ? ToucanColors.blue[300] : ToucanColors.blue[200]};
          }
          & > * {
            color: ${isActive ? ToucanColors.blue[400] : ToucanColors.blue[200]};
          }
        }
      `}
    >
      <img
        src={languageInfo.imagePath}
        alt={`${languageInfo.language} flag`}
        css={css`
          width: 21px;
          margin-right: ${theme.spacing(1)};
        `}
      />
      <Typography
        variant="xs"
        isBold
        css={css`
          color: ${isActive ? ToucanColors.blue[400] : ToucanColors.gray[500]};
          transition: color 0.25s ease-in-out;
        `}
      >
        {languageInfo.language}
      </Typography>
      <div
        css={css`
          position: absolute;
          height: ${isActive ? '4px' : '1px'};
          background-color: ${isActive ? ToucanColors.blue[300] : ToucanColors.gray[100]};
          width: 100%;
          bottom: 0;
          left: 0;
          transition: all 0.25s ease-in-out;
        `}
      />
    </TransparentButton>
  );
};

interface LanguageSelectorsProps {
  activeLanguage: Language;
  onChooseLanguage: (language: Language) => void;
}

export const LanguageSelectors: FC<LanguageSelectorsProps> = ({ onChooseLanguage, activeLanguage }) => {
  const theme = useTheme();
  const { supportedLanguages } = useSupportedLanguages({ areAllLanguagesIncluded: true });

  const { uiLanguage } = useUILanguage();
  const { getLanguageInfoFromLanguageId } = useLanguageInfo();

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        justify-content: flex-start;
        z-index: 100;
        height: auto;
        background-color: ${ToucanColors.white};
        top: 0;
        ${theme.breakpoints.up('sm')} {
          justify-content: center;
        }
        ${theme.breakpoints.up('md')} {
          height: 100%;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          overflow-x: scroll;
          z-index: 2;
          /* Hide scrollbar for Chrome, Safari and Opera */
          ::-webkit-scrollbar {
            display: none;
          }
        `}
      >
        {supportedLanguages[uiLanguage].map((languageId) => {
          const languageKey = getLanguageInfoFromLanguageId(languageId)?.key;
          return (
            <LanguageSelector
              key={languageKey}
              language={languageKey as Language}
              onClick={onChooseLanguage}
              isActive={activeLanguage === languageKey}
            />
          );
        })}
      </div>
      <div
        css={css`
          height: 1px;
          position: absolute;
          background-color: ${ToucanColors.gray[100]};
          width: 100%;
          bottom: 0;
          z-index: 1;
        `}
      />
    </div>
  );
};
