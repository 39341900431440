import { ReactNode, FC } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation, Trans } from 'next-i18next';
import { Waypoint } from 'react-waypoint';

import { ResponsiveElements } from '~/components/shared/ResponsiveElements';
import { Language } from '~/constants/languages';
import { useLanguageInfo } from '~/hooks/useLanguageInfo';
import { HomeLanguageKey, useToucanTranslation } from '~/hooks/useToucanTranslation';

import { useUILanguage } from '../LocaleProvider';
import { InlineTranslation } from '../shared/InlineTranslation';

const { Typography } = ToucanComponents;

interface DescriptionProps {
  category: string;
  children: ReactNode;
  title: ReactNode;
}

// Section that includes a subheader, header, and description of the benefit
const Description: FC<DescriptionProps> = ({ category, children, title }) => {
  const theme = useTheme();

  return (
    <div>
      <Typography
        variant="sm"
        isBold
        color={ToucanColors.gray[400]}
        css={css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        {category}
      </Typography>
      <ResponsiveElements
        responsiveProps={{
          xs: {
            variant: 'md',
          },
          md: {
            variant: 'lg',
          },
        }}
      >
        <Typography
          font="secondary"
          color={ToucanColors.gray[600]}
          css={css`
            margin-bottom: ${theme.spacing(2)};
            ${theme.breakpoints.up('md')} {
              margin-bottom: ${theme.spacing(3)};
            }
          `}
        >
          {title}
        </Typography>
      </ResponsiveElements>
      <Typography
        variant="sm"
        color={ToucanColors.gray[500]}
        css={css`
          ${theme.breakpoints.up('sm')} {
            width: 284px;
          }
          ${theme.breakpoints.up('md')} {
            width: 330px;
          }
        `}
      >
        {children}
      </Typography>
    </div>
  );
};

interface SectionProps {
  demoDropShadow?: string;
  imageUrl: string;
  imageAlt: string;
  description: ReactNode;
  isDescriptionFirst?: boolean;
  isLastSection?: boolean;
}

// Container for each benefit
const Section: FC<SectionProps> = ({
  imageUrl,
  imageAlt,
  demoDropShadow,
  description,
  isDescriptionFirst,
  isLastSection,
}) => {
  const theme = useTheme();

  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
        max-width: 1200px;
        width: 100%;
        margin-bottom: ${theme.spacing(16)};
        ${theme.breakpoints.up('sm')} {
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: ${theme.spacing(isLastSection ? 5 : 20)};
        }
        ${theme.breakpoints.up('md')} {
          margin-bottom: ${theme.spacing(isLastSection ? 13 : 30)};
        }
      `}
    >
      <div
        css={css`
          ${!isDescriptionFirst && `margin-bottom: ${theme.spacing(5)};`}
          order: 1;
          flex: 1;
          ${theme.breakpoints.up('sm')} {
            ${isDescriptionFirst && `order: 2`}
            margin-bottom: 0;
            ${!isDescriptionFirst && `margin-right: ${theme.spacing(5)};`}
            ${demoDropShadow && `box-shadow: ${demoDropShadow};`}
          }
          ${theme.breakpoints.up('md')} {
            margin-right: 0;
            max-width: 566px;
          }
        `}
      >
        <img
          src={imageUrl}
          alt={imageAlt}
          css={css`
            width: 100%;
            display: block;
          `}
        />
      </div>
      <div
        css={css`
          flex: 1;
          ${isDescriptionFirst && `margin-bottom: ${theme.spacing(5)};`}
          order: 2;
          ${theme.breakpoints.up('sm')} {
            ${isDescriptionFirst && `order: 1`}
            ${isDescriptionFirst && `margin-right: ${theme.spacing(5)};`}
            flex: 1;
            display: flex;
            justify-content: center;
          }
          ${theme.breakpoints.up('md')} {
            margin-right: 0;
          }
        `}
      >
        {description}
      </div>
    </Box>
  );
};

interface BenefitsProps {
  language: Language;
  onViewSection: () => void;
}

const Benefits: FC<BenefitsProps> = ({ language, onViewSection }) => {
  const theme = useTheme();
  const { t } = useTranslation('home');
  const { uiLanguage: sourceLanguage } = useUILanguage();
  const { getLanguageInfoFromLanguageId } = useLanguageInfo();
  const { tt: ttSourceLanguage } = useToucanTranslation(getLanguageInfoFromLanguageId(sourceLanguage)?.key as Language);
  const { tt: ttTargetLanguage } = useToucanTranslation(language);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      css={css`
        padding: ${theme.spacing(8, 4)};
        padding-bottom: 0;
        ${theme.breakpoints.up('sm')} {
          padding: ${theme.spacing(10, 6)};
          padding-bottom: 0;
        }
        ${theme.breakpoints.up('md')} {
          padding: ${theme.spacing(15, 18)};
          padding-bottom: 0;
        }
      `}
    >
      <Waypoint onEnter={onViewSection} />
      <Section
        demoDropShadow="0px 4px 12px rgba(22, 22, 22, 0.04)"
        imageUrl={`/images/home/${sourceLanguage}/benefits-immersion.svg`}
        imageAlt={t('instantTranslationsExample')}
        description={
          <Description
            category={t('instantTranslations')}
            title={
              <Trans
                i18nKey="immersionMade"
                ns="home"
                values={{
                  easy: ttTargetLanguage(HomeLanguageKey.Easy),
                }}
                components={[
                  <InlineTranslation sourcePhrase={ttSourceLanguage(HomeLanguageKey.Easy)} language={language}>
                    aaa
                  </InlineTranslation>,
                ]}
              />
            }
          >
            <Trans
              i18nKey="ultimateMultitaskingTool"
              ns="home"
              values={{
                words: ttTargetLanguage(HomeLanguageKey.Words),
                language: ttTargetLanguage(HomeLanguageKey.Language),
              }}
              components={[
                <InlineTranslation sourcePhrase={ttSourceLanguage(HomeLanguageKey.Words)} language={language}>
                  aaa
                </InlineTranslation>,
                <InlineTranslation sourcePhrase={ttSourceLanguage(HomeLanguageKey.Language)} language={language}>
                  aaa
                </InlineTranslation>,
              ]}
            />
          </Description>
        }
      />
      <Section
        imageUrl={`/images/home/${sourceLanguage}/benefits-knowledge.svg`}
        imageAlt={t('minigameExample')}
        description={
          <Description category={t('quizzesAndGames')} title={t('testYourKnowledge')}>
            <Trans
              i18nKey="assessYourSkills"
              ns="home"
              values={{
                language: ttTargetLanguage(HomeLanguageKey.Language),
                games: ttTargetLanguage(HomeLanguageKey.Games),
              }}
              components={[
                <InlineTranslation sourcePhrase={ttSourceLanguage(HomeLanguageKey.Language)} language={language}>
                  aaa
                </InlineTranslation>,
                <InlineTranslation sourcePhrase={ttSourceLanguage(HomeLanguageKey.Games)} language={language}>
                  aaa
                </InlineTranslation>,
              ]}
            />
          </Description>
        }
        isDescriptionFirst
      />
      <Section
        imageUrl={`/images/home/${sourceLanguage}/benefits-motivation.svg`}
        imageAlt={t('progressAndLevelUpExample')}
        description={
          <Description category={t('progressAndLevels')} title={t('stayMotivated')}>
            <Trans
              i18nKey="trackProgress"
              ns="home"
              values={{
                progress: ttTargetLanguage(HomeLanguageKey.Progress),
              }}
              components={[
                <InlineTranslation sourcePhrase={ttSourceLanguage(HomeLanguageKey.Progress)} language={language}>
                  aaa
                </InlineTranslation>,
              ]}
            />
          </Description>
        }
        isLastSection
      />
    </Box>
  );
};

export default Benefits;
