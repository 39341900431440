import { memo } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { Box, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';

import { formatPartnerBanner } from '~/helpers/partnerBanner';

const { Typography } = ToucanComponents;

interface PartnerBannerProps {
  partner: string | undefined;
}

export const PartnerBanner = memo(({ partner }: PartnerBannerProps) => {
  const theme = useTheme();
  const { t } = useTranslation('home');

  if (!partner) {
    return null;
  }

  const sanitizedPartnerName = formatPartnerBanner(partner);

  return (
    <Box
      css={css`
        background-image: url(/images/home/partner/bannerPartner-base.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      `}
    >
      <Box
        display="flex"
        justifyContent="center"
        css={css`
          width: 100%;
          background-image: url(/images/home/partner/bannerPartner-top.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          p {
            color: ${ToucanColors.teal[100]};
          }
        `}
        pl={2.5}
        pr={2.5}
        pt={2}
        pb={2}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          css={css`
            img {
              margin-right: ${theme.spacing(1)};
            }
            span {
              color: ${ToucanColors.white};
              font-weight: 700;
              white-space: pre;
            }
          `}
        >
          <img src="/images/sparkle.svg" alt={t('sparkleImage')} height="16px" width="16px" />
          <Typography variant="sm">
            <Trans
              i18nKey="partnerWelcomeBanner"
              ns="home"
              values={{ sanitizedPartnerName }}
              components={[<span>{sanitizedPartnerName}</span>]}
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
});
