import { useEffect, useState } from 'react';

import { camelCase, capitalize } from 'lodash';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';

import { Banner } from '~/components/Home/Banner';
import Benefits from '~/components/Home/Benefits';
import { HowItWorks } from '~/components/Home/HowItWorks';
import { PartnerBanner } from '~/components/Home/PartnerBanner';
import { Reviews } from '~/components/Home/Reviews';
import { useUILanguage } from '~/components/LocaleProvider';
import { SeoOverrides } from '~/components/Seo/SeoOverrides';
import { Box } from '~/components/shared/Box';
// import FooterPrivacyAlert from '~/components/shared/FooterPrivacyAlert';
import { LanguageSelectors } from '~/components/shared/LanguageSelectors';
import Layout from '~/components/shared/Layout';
import Navbar from '~/components/shared/Navbar';
import COOKIES from '~/constants/cookies';
import { Events } from '~/constants/events';
import { Language } from '~/constants/languages';
import { ExistingPartner } from '~/constants/partner';
import { SocialLink } from '~/constants/urls';
import { SsrGlobalContext } from '~/context/GlobalContext';
import { trackEvent } from '~/helpers/analytics';
import { useCookie } from '~/helpers/cookies';
import Errors from '~/helpers/errors';
import { getConfig } from '~/helpers/extensionDeviceConfig';
import { useLanguageInfo } from '~/hooks/useLanguageInfo';
import useLocalStorage from '~/hooks/useLocalStorage';
import { useSupportedLanguages } from '~/hooks/useSupportedLanguages';
import { createApolloClient } from '~/lib/apollo';
import Config from '~/lib/config';
import { ExtensionConfig } from '~/lib/extension-config';
import { getTranslations } from '~/lib/next-i18next';
import {
  ExtensionStatDocument,
  ExtensionStatQuery,
  ExtensionStatQueryVariables,
  useExtensionStatQuery,
} from '~/networking/schema';
import { DeviceConfig, ToucanPage } from '~/types/next';

interface HomeProps {
  extensionStats: ExtensionStatQuery['extensionStat'];
  linkedLanguage?: Language;
  partner?: ExistingPartner;
  extensionConfig: ExtensionConfig;
  deviceConfig: DeviceConfig;
}

const defaultOpenGraphImage = 'images/home/en/open-graph-default.png';

export const useHomepageCookies = (partner?: string) => {
  const numDaysActive = 30;
  const { query } = useRouter();
  const [, setAdPlatformCookie] = useCookie(COOKIES.AD_PLATFORM);
  const [, setAttributionGroupCookie] = useCookie(COOKIES.ATTRIBUTION_GROUP);
  const [, setPartnerCookie] = useCookie(COOKIES.PARTNER);
  const [, setPartnerSpecificPropertiesCookie] = useCookie(COOKIES.PARTNER_SPECIFIC_PROPERTIES);

  const setPartnerCookieAndIdentifyUser = (partnerToSet: string) => {
    setPartnerCookie({
      value: partnerToSet,
      numDaysActive,
    });
    const { amplitude } = window;
    if (amplitude) {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('partner', partnerToSet);
      amplitude.identify(identifyEvent);
    }
  };

  useEffect(() => {
    if (query.adPlatform) {
      setAdPlatformCookie({
        value: query.adPlatform as string,
        numDaysActive,
      });
    }
  }, [query.adPlatform]);

  useEffect(() => {
    if (query.attributionGroup) {
      setAttributionGroupCookie({
        value: query.attributionGroup as string,
        numDaysActive,
      });
    }
  }, [query.attributionGroup]);

  useEffect(() => {
    if (partner) {
      setPartnerCookieAndIdentifyUser(partner);
    }
  }, [partner]);

  useEffect(() => {
    const partnerSpecificProperties =
      query.referrer === 'bold' && query.subId1 ? { bold: { subId1: query.subId1 } } : undefined;
    if (partnerSpecificProperties) {
      if (Object.keys(partnerSpecificProperties)[0]) {
        setPartnerCookieAndIdentifyUser(Object.keys(partnerSpecificProperties)[0]);
      }
      setPartnerSpecificPropertiesCookie({
        value: JSON.stringify(partnerSpecificProperties),
        numDaysActive,
      });
    }
  }, [query.referrer, query.subId1]);
};

const Home: ToucanPage<HomeProps> = ({ extensionStats, linkedLanguage, partner, extensionConfig, deviceConfig }) => {
  const { uiLanguage } = useUILanguage();
  const { getDefaultLanguageInfo, getLanguageId, getLanguageFromLanguageId } = useLanguageInfo();
  const [selectedLanguageState, setSelectedLanguage] = useState<Language>(
    () => linkedLanguage ?? getDefaultLanguageInfo(uiLanguage).key,
  );
  const [isCtaInView, setIsCtaInView] = useState<boolean | undefined>();
  const [sectionsViewed, setSectionsViewed] = useState<Record<string, boolean>>({});
  const [, setLinkedLanguage] = useLocalStorage(SocialLink.LinkedLanguage, '');
  const { supportedLanguages } = useSupportedLanguages({ areAllLanguagesIncluded: true });
  const { data } = useExtensionStatQuery();

  useEffect(() => {
    if (linkedLanguage) {
      setLinkedLanguage(linkedLanguage);
      trackEvent({
        name: Events.SharedHomePageViewed,
        properties: {
          language: linkedLanguage,
        },
      });
    }
  }, [linkedLanguage]);

  const currentStateLanguageSupported = supportedLanguages[uiLanguage].includes(getLanguageId(selectedLanguageState));

  const selectedLanguage = currentStateLanguageSupported
    ? selectedLanguageState
    : getDefaultLanguageInfo(uiLanguage).key;

  const trackViewSection = (sectionName: string) => {
    return () => {
      if (!sectionsViewed[camelCase(sectionName)]) {
        trackEvent({
          name: Events.HomePageSectionViewed,
          properties: {
            homePageSectionName: sectionName,
          },
        });
        setSectionsViewed({
          ...sectionsViewed,
          [camelCase(sectionName)]: true,
        });
      }
    };
  };

  const onChooseLanguage = (language: Language) => {
    setSelectedLanguage(language);
    trackEvent({
      name: Events.HomePageLanguageSelectionClicked,
      properties: {
        sourceLanguage: capitalize(getLanguageFromLanguageId(uiLanguage)),
        targetLanguage: capitalize(language),
      },
    });
  };

  const onEnterButton = () => {
    setIsCtaInView(true);
  };

  const onLeaveButton = () => {
    setIsCtaInView(false);
  };

  useHomepageCookies(partner);

  return (
    <SsrGlobalContext extensionConfig={extensionConfig} deviceConfig={deviceConfig}>
      {linkedLanguage && (
        <SeoOverrides
          openGraph={{
            images: [
              {
                url: `${Config.SITE}/${
                  linkedLanguage === Language.English
                    ? defaultOpenGraphImage
                    : `images/home/en/open-graph-${getLanguageId(linkedLanguage)}.png`
                }`,
                width: 1200,
                height: 630,
              },
            ],
          }}
        />
      )}
      <Layout
        pageError={Errors.IndexPageError}
        isContained={false}
        customNavbar={
          <Navbar
            addOnContent={
              <Box>
                <LanguageSelectors onChooseLanguage={onChooseLanguage} activeLanguage={selectedLanguage} />
              </Box>
            }
            isAddForFreeButtonVisible={!isCtaInView}
          />
        }
      >
        <PartnerBanner partner={partner} />
        <Banner
          language={selectedLanguage}
          onLeaveButton={onLeaveButton}
          onEnterButton={onEnterButton}
          numUsers={extensionStats?.users ?? data?.extensionStat?.users ?? undefined}
          numReviews={extensionStats?.reviews ?? data?.extensionStat?.reviews ?? undefined}
          onViewSection={trackViewSection('Hero')}
        />
        <HowItWorks language={selectedLanguage} onViewSection={trackViewSection('How It Works')} />
        <Benefits language={selectedLanguage} onViewSection={trackViewSection('Features')} />
        <Reviews
          language={selectedLanguage}
          numUsers={extensionStats?.users ?? data?.extensionStat?.users ?? undefined}
          onViewSection={trackViewSection('Reviews')}
          onViewCta={trackViewSection('Call-To-Action')}
        />
      </Layout>
    </SsrGlobalContext>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ req, locale }) => {
  const client = createApolloClient(req);
  const { data } = await client.query<ExtensionStatQuery, ExtensionStatQueryVariables>({
    query: ExtensionStatDocument,
  });
  const { extensionConfig, deviceConfig } = getConfig(req.headers['user-agent']);

  return {
    props: {
      extensionStats: data.extensionStat,
      extensionConfig,
      deviceConfig,
      ...(await getTranslations({
        locale,
        namespaces: 'home',
      })),
    },
  };
};

export default Home;
