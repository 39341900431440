import { useState } from 'react';

import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import CarouselCard from './Card';
import CarouselContainer from './Container';
import { useReviews } from './reviews';

const animationTimeSeconds = 0.5;
const secondaryCardDelaySeconds = 0.4;

const Carousel = () => {
  const theme = useTheme();
  const [primarySlideId, setPrimarySlideId] = useState(1);
  const [secondarySlideId, setSecondarySlideId] = useState(2);
  const [areCardsVisible, setAreCardsVisible] = useState(true);
  const reviews = useReviews();

  const getNextSlideIds = () => {
    return {
      primarySlideId: secondarySlideId,
      secondarySlideId: secondarySlideId < reviews.length ? secondarySlideId + 1 : 1,
    };
  };

  const goToNextSlide = () => {
    const nextSlideIds = getNextSlideIds();

    setAreCardsVisible(false);

    setTimeout(() => {
      setPrimarySlideId(nextSlideIds.primarySlideId);
      setSecondarySlideId(nextSlideIds.secondarySlideId);

      setAreCardsVisible(true);
    }, (animationTimeSeconds + secondaryCardDelaySeconds) * 1000);
  };

  return (
    <CarouselContainer onNextClick={goToNextSlide}>
      <CarouselCard
        css={css`
          margin-right: ${theme.spacing(2)};
          ${theme.breakpoints.up('sm')} {
            margin-right: ${theme.spacing(3)};
          }
        `}
        id={primarySlideId}
        author={reviews[primarySlideId - 1].author}
        language={reviews[primarySlideId - 1].language}
        isCardVisible={areCardsVisible}
        visibleOpacity={1}
      >
        {reviews[primarySlideId - 1].text}
      </CarouselCard>

      <CarouselCard
        id={secondarySlideId}
        author={reviews[secondarySlideId - 1].author}
        language={reviews[secondarySlideId - 1].language}
        isCardVisible={areCardsVisible}
        visibleOpacity={0.3}
        animationDelaySeconds={secondaryCardDelaySeconds}
      >
        {reviews[secondarySlideId - 1].text}
      </CarouselCard>
    </CarouselContainer>
  );
};

export default Carousel;
