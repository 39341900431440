import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { useLanguageInfo } from '~/hooks/useLanguageInfo';

const { Typography } = ToucanComponents;

const Author = ({ name, language }) => {
  const theme = useTheme();
  const { getLanguageInfo } = useLanguageInfo();
  const { t } = useTranslation('home');
  const info = getLanguageInfo(language);

  return (
    <div
      css={css`
        position: absolute;
        bottom: ${theme.spacing(4)};
        left: ${theme.spacing(4)};
        ${theme.breakpoints.up('md')} {
          bottom: 70px;
          left: 64px;
        }
      `}
    >
      <Typography variant="sm" isBold color={ToucanColors.gray[400]}>
        {name}
      </Typography>
      <Box display="flex">
        <Typography variant="xs" color={ToucanColors.gray[300]}>
          {t('learningLanguage', {
            language: info.language,
            context: info.languageId,
          })}
        </Typography>
        <img
          src={info.imagePath}
          alt={`${info.language} flag`}
          css={css`
            width: 16px;
            height: 16px;
            margin-left: ${theme.spacing(1)};
          `}
        />
      </Box>
    </div>
  );
};

Author.propTypes = {
  name: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

export default Author;
